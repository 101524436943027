import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { createConfig, http, WagmiProvider } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import { walletConnect } from "wagmi/connectors";

const client = new QueryClient();
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
const metadataUrl = process.env.REACT_APP_METADATA_URL;

if (!projectId) {
  throw new Error("Please provide project ID");
}

if (!metadataUrl) {
  throw new Error("Please provide metadata url");
}

const metadata = {
  name: "Shaolin soccer",
  description: "Web3Modal Example",
  url: metadataUrl, // origin must match your domain & subdomain
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [sepolia] as const;
const wagmiConfig = createConfig({
  chains,
  transports: {
    // [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
  connectors: [walletConnect({ projectId, metadata, showQrModal: false })],
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  themeVariables: {
    "--w3m-z-index": 1001,
  },
  allWallets: "HIDE",
  enableAnalytics: true,
  enableOnramp: true,
});

export function Web3ModalProvider({ children }: any) {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={client}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
