import Envelop from "../../../../../assets/envelop.svg";
import { loginGoogle } from "../../../../../config/requests";
import { Separator } from "../../../AdditionalComponents";
import LoginForm from "../../../Form/LoginForm";
import LoginModal from "../../../LoginModal";
import GoogleAuthButton from "../../GoogleAuthButton/GoogleAuthButton";
import MainMenuButton from "../../MainMenuButton/MainMenuButton";
import React, { useCallback, useEffect, useState } from "react";

interface ISignInButton {
  updateProfile: () => void;
  mainModalClose: () => void;
}

export default function SignInButton({
  updateProfile,
  mainModalClose,
}: ISignInButton) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");

  const handleButtonClick = useCallback(async () => {
    setIsOpen(true);
  }, [isOpen]);

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleGoogleAuth = async () => {
      const response = await (await loginGoogle()).json();
      setLink(response.link);
    };
    handleGoogleAuth();
  }, []);
  return (
    <>
      <MainMenuButton src={Envelop} title="E-mail" action={handleButtonClick} />
      <LoginModal isOpen={isOpen} onClose={onClose}>
        <GoogleAuthButton href={link}>via Google</GoogleAuthButton>
        <Separator>or</Separator>
        <LoginForm
          setClose={() => {
            onClose();
            mainModalClose();
            updateProfile();
          }}
        />
      </LoginModal>
    </>
  );
}
